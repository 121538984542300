import TurbolinksAdapter from "vue-turbolinks";
import Vue from 'vue/dist/vue.esm';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import AllocationReportPage from '../components/AllocationReportPage.vue';
import Attestation from '../components/Attestation.vue';
import BirthdaysExportTable from '../components/BirthdaysExportTable.vue';
import ContactsExportTable from '../components/ContactsExportTable.vue';
import EffortListExportTable from '../components/EffortListExportTable.vue';
import LocationsPage from '../components/LocationsPage.vue';
import TeamMemberTable from '../components/TeamMemberTable.vue';
import TimeEntryInput from '../components/TimeEntryInput.vue';
import TimekeepingExportTable from '../components/TimekeepingExportTable.vue';
import HoursReportPage from '../components/HoursReportPage.vue';
import AdvancedHoursReportPage from '../components/AdvancedHoursReportPage.vue';
import PTOAccountsExportTable from '../components/PTOAccountsExportTable';
import PTOTransactionsExportTable from '../components/PTOTransactionsExportTable';
import PTOReportExportTable from '../components/PTOReportExportTable';
import QualificationsAuditExportTable from '../components/QualificationsAuditExportTable.vue';
import QualificationPage from '../components/QualificationPage.vue';
import QualificationErrorIndicator from '../components/QualificationErrorIndicator.vue';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(TurbolinksAdapter);
Vue.component("allocation-report-page", AllocationReportPage);
Vue.component("attestation", Attestation);
Vue.component("birthdays-export-table", BirthdaysExportTable);
Vue.component("contacts-export-table", ContactsExportTable);
Vue.component("effort-list-export-table", EffortListExportTable);
Vue.component("locations-page", LocationsPage);
Vue.component("team-member-table", TeamMemberTable);
Vue.component("qualification-page", QualificationPage);
Vue.component("qualification-error-indicator", QualificationErrorIndicator);
Vue.component("time-entry-input", TimeEntryInput);
Vue.component("timekeeping-export-table", TimekeepingExportTable);
Vue.component("hours-report-page", HoursReportPage);
Vue.component("advanced-hours-report-page", AdvancedHoursReportPage);
Vue.component("pto-accounts-export-table", PTOAccountsExportTable);
Vue.component("pto-transactions-export-table", PTOTransactionsExportTable);
Vue.component("pto-report-export-table", PTOReportExportTable);
Vue.component("qualifications-audit-export-table", QualificationsAuditExportTable);
document.addEventListener("turbolinks:load", () => {
  const app = new Vue({
    el: '[data-behavior="vue"]'
  });
});